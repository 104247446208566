var instagram = [];
var images_loaded = 0;
var insta_to_get = 8;

var media = [],
  i = 0,
  m = 0,
  endString = "",
  iLength = 1,
  loadString = "",
  loadCount = iLength,
  loadCIndex = 1,
  position = 0,
  totalLength = 0,
  noLoad = false,
  percent_loaded = 0,
  insta_to_show = 18;


function printInsta(insta) {
  var textString = '',
    description = '';
  var thisDetails = '',
    n_classes = '';
  //var image_num = i + 1;
  //
  if (insta.description.length > 60) {
    description = insta.description.substring(0, 60) + '...';
  } else {
    description = insta.description;
  }

  if (insta.is_video) {
    var extra_classes = '';
    if (insta.landscape) {
      extra_classes = 'landscape';
    }
    textString = "<div class='ig video " + extra_classes + "'>";
    textString += "<div class='likes'><b>" + insta.likes + " Likes</b></div>";
    textString += "<div class='inner'>";
    textString += "<a href='" + insta.url + "' target='_blank'>";
    textString += "<video loop autoplay muted>";
    textString += "<source src='" + insta.image + "'/>";
    textString += "</video>";
    textString += "</a>";
    textString += "</div>";
    textString += "</div>";
  } else {
    textString = "<div class='ig'>";
    textString += "<div class='likes'><b>" + insta.likes + " Likes</b></div>";
    textString += "<div class='inner'>";
    //textString += "<div class='like'>" + insta.likes + " Likes</div>";
    textString += "<a href='" + insta.url + "' target='_blank'><img src='" + insta.image + "' class='imgType_" + insta.type + "' alt='" + description + "'></a>";
    textString += "</div>";
    textString += "</div>";
  }
  jQuery("#insta_wrapper").append(textString);
}

function showImages() {
  instagram.forEach(function(insta) {
    printInsta(insta);
    i++;
  });

  jQuery("#insta_wrapper").append('<div class="clearfix"></div>');

  jQuery(".instagram").addClass("loaded");
}




function getInstagram() {

  console.log('here');

  jQuery("#insta_wrapper").html("");

  jQuery.get(
    "https://api.instagram.com/v1/users/" + vars.insta_uid + "/media/recent/?access_token=" + vars.insta_token + "&count=" + insta_to_get,
    function(val) {

      if (val.data) {
        val.data.forEach(function(insta) {
          var isVideo = '';

          if (insta.caption == null) {
            captionText = "";
          } else {
            captionText = insta.caption.text;
          }
          if (insta.videos == null) {
            //mediaSrc = insta.images.thumbnail.url.replace('s150x150/', 's640x640/');
            mediaSrc = insta.images.standard_resolution.url;
            isVideo = 0;
          } else {
            mediaSrc = insta.videos.standard_resolution.url;
            isVideo = 1;
            if (insta.videos.standard_resolution.height === 360) {
              landscape = true;
            }
          }

          var utcSeconds = insta.created_time;
          var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
          d.setUTCSeconds(utcSeconds);
          var sdate = d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2) + " " + pad(d.getHours(), 2) + ":" + pad(d.getMinutes(), 2) + ":" + pad(d.getSeconds(), 2) + "Z";

          var newShot = {
            "sdate": sdate,
            "type": "i",
            "imgID": m,
            "is_video": isVideo,
            "image": mediaSrc,
            "poster": insta.images.standard_resolution.url,
            "timestamp": insta.created_time,
            "description": captionText,
            "user": insta.user.full_name,
            "url": insta.link,
            "likes": insta.likes.count
          };
          instagram.push(newShot);


          images_loaded++;
        });
        // We're done. Show stuff.
        showImages();
      }
    },
    "jsonp"
  ).fail(function() {
    console.log("Instagram call failed.");
  });
}

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

// Instagram feed
if (jQuery('#insta_wrapper').length > 0) {
  if (jQuery(document).width() > 768) {
    insta_to_get = 8;
  } else {
    insta_to_get = 4;
  }
  getInstagram();
}